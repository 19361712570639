import { useState, useEffect } from 'react';
// @mui
import PropTypes from 'prop-types';
import { faker } from '@faker-js/faker';

import { Box, Stack, Link, Card, Button, Container, Typography, CardHeader, Grid } from '@mui/material';
// utils
import ButtonGroup from '@mui/material/ButtonGroup';
import { fToNow } from '../../../../utils/formatTime';
// components
import { PlatosList } from '../../products/index';
import {
  AppTasks,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../../app';
import Iconify from '../../../../components/Iconify';
import Scrollbar from '../../../../components/Scrollbar';
import { AddModalSetions } from './index';
import PRODUCTS from '../../../../_mock/products';
import useConsultPlateDashBoard from '../../../../hooks/consult/useConsultPlateDashBoard';
import { useAuth } from '../../../auth/AuthProvider';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  const auth = useAuth();
  // auth.getAccessToken(),

  const resp = useConsultPlateDashBoard(auth.getAccessToken());
  const [opeModal, setOpeModal] = useState(false);

  const [opeModalContorn, setOpeModalContorn] = useState(false);

  const abreModal = () => {
    setOpeModal(true);
  };

  const handleClose = () => {
    setOpeModal(false);
    setOpeModalContorn(false);
  };

  return (
    <>
      <Card {...other}>
        {<AddModalSetions open={opeModal} handleClose={handleClose} setOpeModal={setOpeModal} />}{' '}
        <Stack spacing={1} sx={{ p: 2, pr: 0 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CardHeader title={title} subheader={subheader} />
              </Grid>
              <Grid item xs={2} sm={6} md={6}>
                <Box sx={{ p: 2, textAlign: 'right' }}>
                  <ButtonGroup>
                    {/* <Button color="inherit" variant="outlined" onClick={other.onImageUpload}>
                      Nuevo contorno
                    </Button> */}
                    <Button
                      startIcon={<Iconify icon={'ic:round-plus'} />}
                      color="primary"
                      variant="contained"
                      onClick={abreModal}
                    >
                      Crear Plato {/* <DeleteForeverIcon /> */}
                    </Button>
                  </ButtonGroup>
                  {/* fin de hearder */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </Card>
      {/* <DataTable /> */}
      <Grid container spacing={3}>
        <Grid sx={{ mt: 1.5 }} item xs={12} md={8} lg={8}>
          <PlatosList products={PRODUCTS}  data={resp} />
        </Grid>
        <Grid sx={{ mt: 1.5 }} item xs={12} md={4} lg={4}>
          <AppTasks
            title="Contornos"
            subheader={'Agrega contorno a tus platos'}
            list={[
              { id: '1', label: 'Create FireStone Logo' },
              { id: '2', label: 'Add SCSS and JS files if required' },
              { id: '3', label: 'Stakeholder Meeting' },
              { id: '4', label: 'Scoping & Estimations' },
              { id: '5', label: 'Sprint Showcase' },
            ]}
          />
        </Grid>
      </Grid>

      {/* <PlatosList sx={{ mt: 1.5 }} products={PRODUCTS} /> */}
    </>
  );
}

// ----------------------------------------------------------------------

NewsItem.propTypes = {
  news: PropTypes.shape({
    description: PropTypes.string,
    image: PropTypes.string,
    postedAt: PropTypes.instanceOf(Date),
    title: PropTypes.string,
  }),
};

function NewsItem({ news }) {
  const { image, title, description, postedAt } = news;

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box component="img" alt={title} src={image} sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }} />

      <Box sx={{ minWidth: 240, flexGrow: 1 }}>
        <Link color="inherit" variant="subtitle2" underline="hover" noWrap>
          {title}
        </Link>

        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
          {description}
        </Typography>
      </Box>

      <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
        {fToNow(postedAt)}
      </Typography>
    </Stack>
  );
}

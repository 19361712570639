import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, CardMedia, Link, Typography, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import ImageUploading from 'react-images-uploading';
import LoadingButton from '@mui/lab/LoadingButton';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

// --------------------------------------------------------------
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { styled } from '@mui/material/styles';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import TextField from '@mui/material/TextField';
import {
  FormProvider,
  RHFTextField,
  SelectDocumenttype,
  TypeCompanySelect,
  PhoneInputnumber,
} from '../../../components/hook-form';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';
import useSelectConsult from '../../../hooks/consult/useConsultCombo';
import DotsMobileStepper from './slider';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

// ----------------------------------------------------------------------

PhotoPlate.propTypes = {
  product: PropTypes.object,
};

export default function PhotoPlate({ ...other }) {
  
  const cantimages = other.images.length;
  const maxNumber = 69;

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (cantimages > 0) {
      setActiveStep(cantimages - 1);
    }
  }, [cantimages]);

  return (
    <>
      <ImageUploading
        multiple
        value={other.images}
        onChange={other.onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
          // write your building UI
          <div>
            {cantimages < 1 && (
              <Button
                //
                sx={{ mt: 1, mb: 2 }}
                fullWidth
                variant="outlined"
                onClick={onImageUpload}
              >
                {cantimages}/3 <AddPhotoAlternateIcon />
              </Button>
            )}

            <Card
              sx={{
                // cursor: 'pointer',
                py: 0,
                boxShadow: 0,
                textAlign: 'center',
              }}

              variant="outlined"
            >
              {cantimages > 0 && (
                <>
                  <DotsMobileStepper
                    images={other.images}
                    onImageRemove={onImageRemove}
                    activeStep={activeStep}
                    cantimages={cantimages === 0 ? 0 : cantimages}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    onImageUpload={onImageUpload}
                  />
                </>
              )}
            </Card>
          </div>
        )}
      </ImageUploading>
    </>
  );
}

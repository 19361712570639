import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AddModalSetions({ value, ...pros }) {

  return (
    <div>
      <Dialog open={pros.open} onClose={pros.handleClose}>
        <DialogTitle>Crea un título contorno</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Este título ayudará a indentificar el grupo de contornos que agregarás luego.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Nombre Contorno"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={pros.handleClose}>Cancelar</Button>
          <Button variant="outlined" color="success" type="submit">
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

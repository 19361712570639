import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';

// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/Label';
import { ColorPreview } from '../../../components/color-utils';

// ----------------------------------------------------------------------
// REACT_APP_VIEWFILE
const url = process.env.REACT_APP_VIEWFILE;

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
  product1: PropTypes.object,
};

export default function ShopProductCard({ product, products }) {
  const [urlphoto, setPhoto] = useState(null);

  const status = 'sale';
  const priceSale = 25.2;

  const { info, plate } = product;

  const { available, code, description, idcategory, idcurrency, name, price, title } = plate;

  const { controlplate, ingregients, photosplate } = info;
  const { company, contour, contouravailable, deleted, id, username } = controlplate;

  window.setTimeout(() => {
    setPhoto(photosplate);
  }, 1);

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        {contour === 'N/A' && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )}
        {urlphoto ? (
          photosplate.map(
            (index) =>
              index.orders === 1 && (
                <div key={index.id}>
                  <img style={{ width: 110, height: 18 }} alt={index.photoname} src={`${url}${index.phototype}`} />
                  <ProductImgStyle alt={name} src={`${url}${index.phototype}`} />
                </div>
              )
          )
        ) : (
          <Skeleton variant="rectangular" width={210} height={118} />
        )}
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="#" color="inherit" underline="hover" component={RouterLink}>
          <Typography variant="subtitle2" noWrap>
            {plate.title}
          </Typography>
        </Link>

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

import { useContext, createContext, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import requestNewAccessToken from './requestNewAccessToken';
import requestRevokToken from './requestRevokToken';
import dataEncrpt from './dataEncry';
import dataDecrypt from './dataDescry';

const rem2 = process.env.REACT_APP_REM2;
const HOSTNAME = process.env.REACT_APP_HOSTNAME;
const rem = process.env.REACT_APP_REM;
const location = window.location;

const AuthContext = createContext({
  isAuthenticated: false,
  isloading: () => {},
  getAccessToken: () => {},
  setAccessTokenAndRefreshToken: () => {},
  getRefreshToken: () => {},
  saveUser: () => {},
  getUser: () => {},
  signout: () => {},
});

export function AuthProvider({ children }) {
  const [cookies, setCookie, removeCookie] = useCookies(['name']);
  const [user, setUser] = useState();
  const [accessToken, setAccessToken] = useState('');
  const [refreshToken, setRefreshToken] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isloading, setIsLoading] = useState(true);

  function getAccessToken() {

    const newItem = { SECRET_KEY: 'Debian123++'};
  
    accessToken[0] = newItem

    return accessToken;
  }

  function saveUser(userData) {
    setAccessTokenAndRefreshToken(userData, userData);
    setUser(userData);
  }

  function setAccessTokenAndRefreshToken(accessToken, tokenbycript) {
    setAccessToken(accessToken);
    setRefreshToken(tokenbycript);
    localStorage.setItem('LKIS', dataEncrpt(accessToken));
    removeCookie('cookieOTP', { path: '/' });
    removeCookie('C307', { path: '/' });
  }

  function getRefreshToken() {
    if (!refreshToken) {
      return refreshToken;
    }
    const token = dataDecrypt(localStorage.getItem('LKIS'));

    if (token) {

      const { refreshToken } = JSON.parse(token);
      setRefreshToken(refreshToken);
      return refreshToken;

    }
    return null;
  }

  async function getNewAccessToken(refreshToken) {
    // conect
    const token = await requestNewAccessToken(refreshToken);

    if (token) {
      return token;
    }
  }

  function getUser() {
    return user;
  }

  function signout() {
    // #
    const token = dataDecrypt(cookies);
    const POSTURL = location.hostname === HOSTNAME ? rem2 : rem;

    // #
    if (token) {
      requestRevokToken(token)
        .then(async (newToken) => {
          setIsAuthenticated(false);
          setIsLoading(false);
          // ###
          window.location.href = POSTURL;
          // #
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setIsAuthenticated(false);
          // ###
          window.location.href = POSTURL;

          // #
        });
    } else {
      setIsLoading(false);
    }
  }

  async function checkAuth() {
    const token = dataDecrypt(cookies);

    try {
      if (accessToken) {
        if (token) {
          // existe access token
          const userInfo = await retrieveUserInfo(accessToken);
          setUser(userInfo);
          setAccessToken(accessToken);
          setIsAuthenticated(true);
          setIsLoading(false);
          console.log('aquiiii', accessToken);
          console.log('aquiiii', 'okokokokokok');
        } else {
          setIsAuthenticated(false);
          signout();
        }
      } else {
        const token = dataDecrypt(cookies);

        if (token) {
          getNewAccessToken(token)
            .then(async (newToken) => {
              if (newToken) {
                // #
                setAccessToken(newToken);
                setIsAuthenticated(true);
                // #
              } else {
                // #
                setIsAuthenticated(false);
                signout();
                // #
              }
            })
            .catch((error) => {
              console.log(error);
              setIsAuthenticated(false);
              setIsLoading(false);
              signout();
            });
        } else {
          setIsAuthenticated(false);
          signout();
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsAuthenticated(false);
    }
  }

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isloading,
        saveUser,
        getAccessToken,
        setAccessTokenAndRefreshToken,
        getRefreshToken,
        getUser,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

async function retrieveUserInfo(accessToken) {
  try {
    const response = await fetch(``, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      const json = await response.json();
      console.log(json);
      return json.body;
    }
  } catch (error) {
    console.log(error); /// **** para capturar error
  }
}

export const useAuth = () => useContext(AuthContext);

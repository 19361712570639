import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// material
import { Grid } from '@mui/material';
import ShopProductCard from './ProductCard';

// ----------------------------------------------------------------------

ProductList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductList({ products, ...other }) {
  const { platescompany } = other.data[0];
  const [platesCompany, setPlatesCompany] = useState([]);

  // console.log('todo>',other.data[0]);

  useEffect(() => {
    setPlatesCompany(platescompany);
  }, [platescompany]);


  return (
    <Grid container spacing={3} {...other}>
      {platesCompany ? (
        platesCompany.map((product) => (
          <Grid key={product.info.controlplate.id} item xs={12} sm={6} md={4}>
            <>
            </>
            <ShopProductCard product={product}  products={products} />
          </Grid>
        ))
      ) : (
        // platesCompany.map((product) => (
        //   <Grid key={product.id} item xs={12} sm={6} md={4}>
        //     <ShopProductCard product={product} product1={platesCompany} />
        //   </Grid>
        // ))
        <div>{/* Render the password protected content */}</div>
      )}
    </Grid>
  );
}

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProductCardAddContorn from '../../products/ProductCardAddContorn';
import PRODUCTS from '../../../../_mock/products';

export default function AddModalContorn({ ...pros }) {

  return (
    <div>
      <Dialog open={pros.open} onClose={pros.handleClose}>
        <DialogTitle>Agrega un contorno</DialogTitle>
        <DialogContent >
          <ProductCardAddContorn product={PRODUCTS[3]} handleClose={pros.handleClose} setOpeModal={pros.setOpeModal} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

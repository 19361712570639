import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// material
import { Grid, Divider } from '@mui/material';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import Alert from '@mui/material/Alert';

// --------------------------------------------------------------
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// utils
import {
  FormProvider,
  SelectAll,
  TextFieldicon2,
  TextFieldicon,
  TextFieldPrice,
  AutoCompletSize,
} from '../../../components/hook-form';
import ProgressCircular from '../../../components/progress/ProgressCircular';
import TransitionAlerts from '../../../components/Alert/TransitionAlerts';

import PhotoPlate from './UploadPhotoPlate';

// components
import { useAuth } from '../../auth/AuthProvider';

// ----------------------------------------------------------------------

const url = process.env.REACT_APP_API_AUTH;
const REGISTER = process.env.REACT_APP_API_AUTH_PLATE_REGISTER;

ProductCardAddContorn.propTypes = {
  product: PropTypes.object,
};

const typecm = [
  { name: 'contorno todo', id: 1994 },
  { name: 'Pollo salsas', id: 1972 },
];

const defaultValues = {
  title: '',
  price: '',
  contorno: { id: '', name: '' },
  description: '',
  idcategory: 1,
};
export default function ProductCardAddContorn({ name, ...other }) {
  const auth = useAuth();

  const [day, setDob] = useState();
  const [form, setForm] = useState();
  const [arrayIngre, setArrayIngre] = useState([]);
  const [nada, setNada] = useState('');
  const [formCount, setFormCount] = useState(defaultValues);
  const [idr, setIdr] = useState(0);
  const [fil, setFil] = useState();
  const [cambio, setCambio] = useState(false);
  const [OpenAlert, setOpenAlert] = useState(false);
  const [Open, setOpen] = useState(false);
  //
  const [images, setImages] = useState([]);
  const [deleteimages, setDeleteImages] = useState(false);
  const [statusAlert, setStatusAlert] = useState(false);
  const [Messenge, setMessenge] = useState('');
  const [severity, setSeverity] = useState('success');
  //

  const RegisterSchema = Yup.object().shape({
    title: Yup.string()
      .required('El nombre del plato no puede estar vacio.')
      .min(2, 'lo minimo son 4 caracteres')
      .max(50, 'Maximo 50 caracteres.'),
    price: Yup.string().required('Agrega un precio').min(1, 'ponle precio a tu plato').max(12, 'Maximo de 10 digitos '),
    contorno: Yup.object().shape({ id: Yup.string().default('null'), name: Yup.string().default('area') }),
    description: Yup.string().required('Detalle brevemente su producto'),
    idcategory: Yup.string().default('1'),
  });
  //
  const methods = useForm({ resolver: yupResolver(RegisterSchema), formCount });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
    watch,
    register,
  } = methods;

  watch((e) => setForm(e.ingre));

  const setingredientes = () => {
    setIdr(idr + 2);
    arrayIngre.push({ name: `#${form}`, id: idr });
    setNada(false);
    setForm('');
    setFormCount(formCount);
  };
  const verifyarray = arrayIngre.slice(-1)[0];

  window.setTimeout(() => {
    if (verifyarray) {
      setFil(verifyarray.name);
    }
  }, 200);

  useEffect(() => {
    if (fil !== `#${form}`) {
      setNada(form);
    } else {
      setForm();
      setNada(false);
    }
  }, [form, fil]);

  const onChange = (imageList) => {
    setCambio(true);
    const ultimoElemento = imageList.at(-1);
    setDeleteImages(ultimoElemento);
    window.setTimeout(() => {
      setImages(imageList);
      setCambio(false);
    }, 800);
  };

  const onSubmit = async (values) => {
    if (images.length > 0) {
      const extractedData = images.map((item) => ({
        data_url: item.data_url,
        file: { name: item.file.name, size: item.file.size, type: item.file.type },
      }));

      const valuePut = {
        verify: auth.getAccessToken(),
        form: values,
        photoplates: extractedData,
        listingred: arrayIngre,
      };

      try {
        setOpen(true);
        const CONFIG_JSON = {
          method: 'POST',
          headers: {
            referrerPolicy: 'unsafe_url',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://kartaqr.com',
            mode: 'same-origin',
          },
          body: JSON.stringify(valuePut),
        };
        const res = await fetch(`${url}${REGISTER}`, CONFIG_JSON);
        const resp = await res.json();

        console.log(res);
        console.log(resp);
        // console.log(resp['message']);

        if (res.status === 200) {
          setOpen(false);
          setStatusAlert(true);
          setMessenge('¡Registrado!');

          window.setTimeout(() => {
            other.setOpeModal(false);
          }, 2000);

          window.setTimeout(() => {
            setStatusAlert(false);
          }, 2000);
        }
        if (res.status !== 200) {
          setOpen(false);
          setStatusAlert(true);
          setMessenge(resp.messenge);
          setSeverity('error')
          

          window.setTimeout(() => {
            setStatusAlert(false);
          }, 2000);
        }

        console.log(res.status);
      } catch (error) {
        console.log(error);
        setOpen(false);
      }
    } else {
      setOpenAlert(true);
      window.setTimeout(() => {
        setOpenAlert(false);
      }, 3000);
    }
  };

  return (
    <>
      <TransitionAlerts severity={severity} Messenge={Messenge} statusAlert={statusAlert} />
      <ProgressCircular open={Open} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldicon2
              id="title"
              name="title"
              label="Contorno"
              variant="standard"
              placeholder="tu contorno debe ser corto"
              //  icon={<FoodBankIcon />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextFieldPrice
              id="price"
              name="price"
              label="Precio"
              variant="standard"
              placeholder="0.00"
              fullWidth
              // icon={<AlternateEmailIcon />}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <SelectAll
              name="contorno"
              apiconect={typecm}
              Optins={'Sin resultados'}
              label="contorno"
              width="100%"
              variant="standard"
            />
          </Grid>
          <Divider />

          <Grid item sx={{ mt: 2, mb: 2 }} xs={12} sm={15}>

            {!OpenAlert &&
              (cambio ? (
                <Card sx={{ Width: '100%', m: 2 }}>
                  <Skeleton width="100%" height={400}>
                    <PhotoPlate deleteimag={deleteimages} onChange={onChange} images={images} product={other.product} />
                  </Skeleton>
                </Card>
              ) : (
                <PhotoPlate deleteimag={deleteimages} onChange={onChange} images={images} product={other.product} />
              ))}

            <Collapse in={OpenAlert}>
              <Alert variant="outlined" severity="warning">
                cargar tus imagenes{' '}
              </Alert>
            </Collapse>

          </Grid>

          <Grid item xs={15}>
            <TextFieldicon
              id="description"
              name="description"
              label="Describe tu producto"
              multiline
              rows={2}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldicon
              id="ingre"
              name="ingre"
              label="Ingredientes"
              variant="standard"
              value={!nada ? '' : nada}
              placeholder="curcuma"
              // icon={<AlternateEmailIcon />}
            />
          </Grid>

          <Grid sx={{ mt: 2, mb: 1 }} item xs={12} sm={6}>
            <Button
              //
              disabled={!form}
              fullWidth
              variant="outlined"
              onClick={setingredientes}
            >
              agregar ingredientes
            </Button>
          </Grid>

          <Grid sx={{ mt: 2, mb: 1 }} item xs={12}>
            <AutoCompletSize
              fullWidth
              width="100%"
              id="ingredients"
              name="ingredients"
              label="lista de ingredientes"
              variant="standard"
              placeholder="con que preparaste"
              arrayIn={arrayIngre}
              //  icon={<FoodBankIcon />}
            />
          </Grid>
        </Grid>
        <DialogActions>
          <Button onClick={other.handleClose}>Cancelar</Button>
          <LoadingButton
            type="submit"
            variant="outlined"
            loading={isSubmitting}
          >
            Crear
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </>
  );
}

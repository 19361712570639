import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ButtonGroup from '@mui/material/ButtonGroup';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

DotsMobileStepper.propTypes = {
  name: PropTypes.object,
};

export default function DotsMobileStepper({ ...other }) {
  const theme = useTheme();

  return (
    <Box sx={{ Width: 400, flexGrow: 1 }}>
      <ButtonGroup
        sx={{
          zIndex: 9,
          top: 16,
          right: 16,
          position: 'absolute',
          textTransform: 'uppercase',
          height: 41,
          width: 'auto',
        }}
      >
        {other.cantimages < 3 && (
          <Button variant="outlined" onClick={other.onImageUpload}>
            {other.cantimages}/3 <AddPhotoAlternateIcon />
          </Button>
        )}

        <Button color="inherit" variant="contained" onClick={() => other.onImageRemove(other.activeStep)}>
          <DeleteForeverIcon />
        </Button>
      </ButtonGroup>

      <img src={other.images[other.activeStep].data_url} alt={other.images[other.activeStep].file.name} />

      <MobileStepper
        variant="text"
        steps={other.cantimages}
        position="static"
        activeStep={other.activeStep}
        nextButton={
          <Button size="small" onClick={other.handleNext} disabled={other.activeStep === other.cantimages - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={other.handleBack} disabled={other.activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </Box>
  );
}

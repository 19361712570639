import { useState, useEffect } from 'react';
import axios from 'axios';

export default function useConsultPlateDashBoard(iverify) {
  const url = process.env.REACT_APP_API_AUTH;
  const GetPlate = process.env.REACT_APP_API_AUTH_PLATE_GET_DASHBOARD;
  const endPoint = `${url}${GetPlate}`;

  const [typecm, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const consulGET = async () => {
    try {
      const response = await axios.get(endPoint, {
        headers: {
          Accept: 'application/json',
          verify: JSON.stringify(iverify),
        },
      });

      setData(response.data);
    } catch (error) {
      console.log('Error_conect');
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    consulGET();
  }, []);

  return [typecm, error, loading];
}

import * as React from 'react';
import PropTypes from 'prop-types';
import { faker } from '@faker-js/faker';
import Box from '@mui/material/Box';
import { AppTrafficBySite } from '../app';
import { SetionsMenu } from './SettionsPartitions';
import { DashboardDishes } from './dishesAdd';
import Iconify from '../../../components/Iconify';

function TabPanel(props) {
  const { children, value, index } = props;

  return value === index && <Box sx={{ p: 3 }}>{children}</Box>;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function PaintConteiner({ value, ...pros }) {
  return (
    <Box sx={{ width: '100%' }}>
      <TabPanel value={value} index={1} title="Dashboard">
        <SetionsMenu
          title="Secciones"
          list={[...Array(5)].map((_, index) => ({
            id: faker.datatype.uuid(),
            title: faker.name.jobTitle(),
            description: faker.name.jobTitle(),
            image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
            postedAt: faker.date.recent(),
          }))}
        />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <DashboardDishes
          title="Platos"
          list={[...Array(5)].map((_, index) => ({
            id: faker.datatype.uuid(),
            title: faker.name.jobTitle(),
            description: faker.name.jobTitle(),
            image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
            postedAt: faker.date.recent(),
          }))}
        />
        <AppTrafficBySite sx={{ mt: 1.5 }} 
          title="Traffic by Site"
          list={[
            {
              name: 'Twitter',
              value: 443232,
              icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
            },
          ]}
        />{' '}
      </TabPanel>

      <TabPanel value={value} index={3}>
        <AppTrafficBySite
          title="Traffic by Site"
          list={[
            {
              name: 'FaceBook',
              value: 323234,
              icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
            },
            {
              name: 'Google',
              value: 341212,
              icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
            },
            {
              name: 'Linkedin',
              value: 411213,
              icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
            },
            {
              name: 'Twitter',
              value: 443232,
              icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
            },
          ]}
        />{' '}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AppTrafficBySite
          title="Traffic by Site"
          list={[
            {
              name: 'FaceBook',
              value: 323234,
              icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} height={32} />,
            },
            {
              name: 'Google',
              value: 341212,
              icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} height={32} />,
            },
            {
              name: 'Linkedin',
              value: 411213,
              icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} height={32} />,
            },
            {
              name: 'Twitter',
              value: 443232,
              icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} height={32} />,
            },
          ]}
        />{' '}
      </TabPanel>
    </Box>
  );
}
